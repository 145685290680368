import { baseAPICreator } from './apiCreator';

const episodeApi = baseAPICreator(
  '/episode',
  undefined,
  process.env.REACT_APP_ENV === 'prod'
    ? 'https://api-episodes.thebarometer.co/api'
    : 'https://api-episodes-dev.thebarometer.co/api'
);

export const parseEpisodesOfShow = async (
  show: string
): Promise<{ status: string; message: string }> => {
  const { data } = await episodeApi.get(`${encodeURIComponent(show)}/parse`);
  return data;
};
