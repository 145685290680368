import { useState, useEffect, useRef } from 'react';
import { decode } from 'html-entities';

import {
  getAllGarmScoresPerShow,
  getFilteredGarmShows,
  getGarmAllEpisodes,
} from 'src/apis';
import {
  IGarmScoreDto,
  IGarmScoreShowDetails,
  GarmShowsRequestBody,
} from 'src/types';
import { ScoreFilterType, ScoreExceptionTypes } from 'src/types/filter';
import {
  downloadAsCsv,
  downloadAsJson,
  convertRatings,
  getValidGarmScores,
} from 'src/utils';
import useDashboardFilters from 'src/hooks/useDashboardFilters';
import { formatPodcastRating } from 'src/utils';
import useDashboardCategories from 'src/hooks/useDashboardCategories';
import useEnvironment from 'src/hooks/useEnvironment';

const DELIMITER = ',';

const ShowHeaderMap: Record<string, string> = {
  'Adult Sexual Explicit': 'GARM 1 Risk',
  'Obscenity & Profanity': 'GARM 7 Risk',
  'Debated Sensitive Social Issues (Political issues)': 'GARM 11 Risk',
  'Illegal Drugs/Tobacco/e-Cigarettes/Vaping/Alcohol': 'GARM 8 Risk',
  'Arms & Ammunition': 'GARM 2 Risk',
  'Online Piracy or Spam': 'GARM 5&9 Risk',
  'Crime & Violation of Human Rights': 'GARM 3 Risk',
  'Death, Injury or Military Conflict': 'GARM 4 Risk',
  'Hate Speech & Acts of Aggression': 'GARM 6 Risk',
  Terrorism: 'GARM 10 Risk',
  Gambling: 'GAMBLING Risk',
  Occult: 'OCCULT Risk',
  'Natural Disasters': 'NATURAL DISASTER Risk',
  Genres: 'Genres',
  'No. of stars': 'No. of stars',
  'Top IAB Categories': 'Top IAB Categories',
  'General Election': 'General Election Risk',
  'US Elections': 'US Elections Risk',
};

const csvShowHeaders = [
  'id',
  'Show',
  'Host',
  // 'HostData',
  'RSS Feed',
  'ImageURL',
  'collectionId',
  'Publisher',
  'Language',
];

const csvEpisodeHeaders = [
  'Episode Title',
  'Guid',
  'Adult Sexual Explicit Data',
  'Adult Sexual Explicit',
  'Arms & Ammunition Data',
  'Arms & Ammunition',
  'Crime & Violation of Human Rights Data',
  'Crime & Violation of Human Rights',
  'Death Injury or Military Conflict Data',
  'Death Injury or Military Conflict',
  'Online Piracy or Spam Data',
  'Online Piracy or Spam',
  'Hate Speech & Acts of Aggression Data',
  'Hate Speech & Acts of Aggression',
  'Obscenity & Profanity Data',
  'Obscenity & Profanity',
  'Illegal Drugs/Tobacco/e-Cigarettes/Vaping/Alcohol Data',
  'Illegal Drugs/Tobacco/e-Cigarettes/Vaping/Alcohol',
  'Terrorism Data',
  'Terrorism',
  'Debated Sensitive Social Issues (Political issues) Data',
  'Debated Sensitive Social Issues (Political issues) Highlight',
  'Debated Sensitive Social Issues (Political issues)',
  'GAMBLING Data',
  'GAMBLING',
  'OCCULT Data',
  'OCCULT',
  'NATURAL DISASTER Data',
  'NATURAL DISASTER',
  'Audio File (AUTO)',
  'id',
  'Podscribe Ep ID',
  'Episode Link',
  'createdAt',
  'itunes stars',
  'Episode Date',
  'Show',
  'Tone - neutral score',
  'Episode Number',
  'Show Code',
  'IAB Data',
  'Transcript Link (AUTO)',
  'Transcription 1',
  'Transcription 2',
  'Transcription 3',
  'Transcription 4',
];

const csvAllEpisodeHeaders = [
  'Episode Title',
  'Guid',
  'Adult Sexual Explicit Data',
  'Adult Sexual Explicit',
  'Arms & Ammunition Data',
  'Arms & Ammunition',
  'Crime & Violation of Human Rights Data',
  'Crime & Violation of Human Rights',
  'Death Injury or Military Conflict Data',
  'Death Injury or Military Conflict',
  'Online Piracy or Spam Data',
  'Online Piracy or Spam',
  'Hate Speech & Acts of Aggression Data',
  'Hate Speech & Acts of Aggression',
  'Obscenity & Profanity Data',
  'Obscenity & Profanity',
  'Illegal Drugs/Tobacco/e-Cigarettes/Vaping/Alcohol Data',
  'Illegal Drugs/Tobacco/e-Cigarettes/Vaping/Alcohol',
  'Terrorism Data',
  'Terrorism',
  'Debated Sensitive Social Issues (Political issues) Data',
  'Debated Sensitive Social Issues (Political issues) Highlight',
  'Debated Sensitive Social Issues (Political issues)',
  'GAMBLING Data',
  'GAMBLING',
  'OCCULT Data',
  'OCCULT',
  'NATURAL DISASTER Data',
  'NATURAL DISASTER',
  'id',
  'Episode Link',
  'Tone - neutral score',
  'Episode Date',
  'Show',
  'IAB Categories',
];

interface IDownlaodData {
  searchText: string;
  dashboardEnv: string;
  totalShowsCount: number;
  order?: 'desc' | 'asc';
  orderBy: string;
  setCancelDownload: (flag: boolean) => void;
}

const useDownload = ({
  searchText,
  dashboardEnv,
  totalShowsCount,
  order,
  orderBy,
  setCancelDownload,
}: IDownlaodData) => {
  const cancelDownloading = useRef(false);
  const [csvDownloading, setCsvDownloading] = useState(false);
  const [jsonDownloading, setJsonDownloading] = useState(false);
  const [showsDownloading, setShowsDownloading] = useState(false);
  // const [hostDataJson, setHostDataJson] = useState<any>({});
  const [downloadProgress, setDownloadProgress] = useState<number>(0);
  const {
    dashboardName,
    garmSourceFilters,
    selectedPublishers,
    selectedIABCategories,
    selectedLanguages,
    riskChangeFilter,
    enabledFilters,
    selectedCustomFilter,
    selectedGenres,
    excludedGenres,
    garmDashboardColumns,
    electionRange,
    selectedCustomTags,
  } = useDashboardFilters();
  const { riskComponents } = useDashboardCategories();
  const { timeRange } = useEnvironment();

  // useEffect(() => {
  //   import('src/assets/hostdata.json').then((data) => {
  //     setHostDataJson(data);
  //   });
  // }, []);

  const fetchEpisodes = async (
    shows: IGarmScoreShowDetails[],
    noEpisodes = false
  ) => {
    let episodes: IGarmScoreDto[] = [];
    if (!noEpisodes) {
      episodes = (
        await Promise.all(
          shows.map((garmShow) => getAllGarmScoresPerShow(garmShow.show))
        )
      ).flat();
    }
    episodes = episodes.map((item) => {
      const iabData = item['IAB New Data']
        ? Object.keys(item['IAB New Data'])
        : item['IAB Data']
        ? Object.keys(item['IAB Data'][0][0])
        : [];
      return {
        ...item,
        'IAB Data': iabData,
        'IAB New Data': undefined,
      };
    });
    const modifiedShows = shows.map((show: IGarmScoreShowDetails) => {
      // const hostList: any = show.hostList
      //   ? show.hostList.split(',').map((host) => host.trim())
      //   : [];

      // const hostdata = hostList.map((host: any) => {
      //   const data: any =
      //     hostDataJson[`agg_${host}` as keyof typeof hostDataJson];
      //   const sentimentX: any = {
      //     article: 0,
      //     score: 0,
      //     articlesDetail: [],
      //   };
      //   const sentimentY: any = {
      //     article: 0,
      //     score: 0,
      //     articlesDetail: [],
      //   };
      //   if (data) {
      //     Object.keys(data.num_of_article || {}).forEach((key) => {
      //       sentimentX.article = data.num_of_article[key];
      //       sentimentX.score = data.total_score[key];
      //       sentimentX.articlesDetail = data.articles[key];
      //       sentimentY.article = sentimentY.article + data.num_of_article[key];
      //       sentimentY.score = sentimentY.score + data.total_score[key];
      //       sentimentY.articlesDetail = [
      //         ...sentimentY.articlesDetail,
      //         ...data.articles[key],
      //       ];
      //     });
      //     sentimentY.score =
      //       sentimentY.score / Object.keys(data.num_of_article).length;
      //   }
      //   return {
      //     lastWeek: sentimentX,
      //     last4Weeks: sentimentY,
      //   };
      // });

      return {
        id: show.id,
        Show: show.show,
        Host: show.hostList || '',
        // HostData: hostdata,
        Genres: show.genreList,
        'No. of stars': show.listenerCount,
        'RSS Feed': show.rssFeedUrl ? show.rssFeedUrl.replaceAll('\r', '') : '',
        ImageURL: show.logoUrl,
        'Top IAB Categories': show.iabCategories?.map((item) => item.name),
        'GARM 1 Risk': show.garm1Risk?.medianRisk,
        'GARM 2 Risk': show.garm2Risk?.medianRisk,
        'GARM 3 Risk': show.garm3Risk?.medianRisk,
        'GARM 4 Risk': show.garm4Risk?.medianRisk,
        'GARM 5&9 Risk': show.garm5Risk?.medianRisk,
        'GARM 6 Risk': show.garm6Risk?.medianRisk,
        'GARM 7 Risk': show.garm7Risk?.medianRisk,
        'GARM 8 Risk': show.garm8Risk?.medianRisk,
        'GARM 10 Risk': show.garm10Risk?.medianRisk,
        'GARM 11 Risk': show.garm11Risk?.medianRisk,
        'GAMBLING Risk': show.gamblingRisk?.medianRisk,
        'OCCULT Risk': show.occultRisk?.medianRisk,
        'NATURAL DISASTER Risk': show.naturalDisasterRisk?.medianRisk,
        // 'Tolerance Risk Avg': show.toleranceRisk?.medianRisk,
        collectionId: show.collectionId,
        publisher: show.publisher,
        filteredEpisodes: show.filteredEpisodes || [],
        language: show.language,
        'General Election Risk': show.generalElectionsRisk?.medianRisk,
        'US Elections Risk': show.electionsPercentage?.medianRiskValue + '%',
      };
    });

    return { shows: modifiedShows, episodes };
  };

  const fetchGarmShows = async (page: number, noEpisodes = false) => {
    try {
      const body: GarmShowsRequestBody = {
        garmExceptions: {},
        page,
        pageSize: noEpisodes ? 1000000 : 100,
      };
      if (orderBy && order) {
        body.sortBy = riskComponents[orderBy] || orderBy.toLowerCase();
        body.sortOrder = order === 'asc' ? 'ASC' : 'DESC';
      }
      if (searchText) {
        if (dashboardEnv === 'Host') {
          body.hostSearch = searchText;
        } else {
          if (searchText.startsWith('#')) {
            body.genreSearch = searchText.slice(1);
          } else {
            body.search = searchText;
          }
        }
      }
      if (garmSourceFilters.length) {
        body.sources = garmSourceFilters;
      }
      if (selectedGenres.length) {
        body.genres = selectedGenres;
      }
      if (excludedGenres.length) {
        body.excludedGenres = excludedGenres;
      }
      if (selectedCustomTags.length) {
        body.customTags = selectedCustomTags;
      }
      if (selectedPublishers.length) {
        body.publishers = selectedPublishers;
      }
      if (selectedIABCategories.length) {
        body.iabCategories = [
          ...selectedIABCategories.map((item) => item.name.toLowerCase()),
          ...selectedIABCategories.map((item) => item.name),
        ];
      }
      if (selectedLanguages.length) {
        body.languages = selectedLanguages;
      }
      if (riskChangeFilter) {
        body.riskLevelDelta = riskChangeFilter;
      }
      if (timeRange) {
        body.dateRange = {
          to: timeRange.to.toISOString(),
          from: timeRange.from.toISOString(),
        };
      }
      const garmExceptions: any = {};
      if (enabledFilters && enabledFilters.length) {
        enabledFilters.forEach((filter) => {
          const filterValue: number[] = filter.value as number[];
          if (filterValue && filter.jsonKeyRisk) {
            if (filter.jsonKeyRisk === 'audience') {
              const values = convertRatings(filterValue);
              if (values) {
                body.listenerCountRange = values;
              }
            } else {
              const values = getValidGarmScores(filterValue);
              if (values) {
                garmExceptions[filter.dashboardFilterKey] = { default: values };
              }
              if (selectedCustomFilter) {
                const customFilter =
                  selectedCustomFilter.data.scoreFilters[filter.title];
                if (customFilter) {
                  garmExceptions[filter.dashboardFilterKey] = {
                    default: getValidGarmScores(customFilter.score),
                  };
                  if (customFilter.content.length) {
                    const content: any = {};
                    customFilter.content.forEach((item: ScoreFilterType) => {
                      if (item.exceptions?.length) {
                        content[
                          item.type === ScoreExceptionTypes.Genre
                            ? 'genre'
                            : 'garmReasoning'
                        ] = item.exceptions;
                      }
                    });
                    garmExceptions[filter.dashboardFilterKey] = {
                      ...garmExceptions[filter.dashboardFilterKey],
                      ...content,
                    };
                  }
                }
              }
            }
          }
        });
      }
      body.garmExceptions = garmExceptions;
      body.electionsExceptions = {
        percentRange: electionRange,
      };
      const { data } = await getFilteredGarmShows(body);
      const newGarmShows = data.map((item: any) => {
        const newItem = {
          ...item,
          iabCategories:
            item.iabCategories &&
            Object.keys(item.iabCategories)
              .map((key) => ({
                count: item.iabCategories[key],
                name: key,
              }))
              .sort((a: any, b: any) => b.count - a.count)
              .slice(0, 3),
          show: decode(item.show),
          showOnDB: item.show,
          listenerCount: formatPodcastRating(item.listenerCount),
        };
        if (item.riskHistories && item.riskHistories.current) {
          Object.keys(item.riskHistories.current).forEach((key: string) => {
            const score = item.riskHistories.current[key].medianRiskValue;
            const oldScore =
              item.riskHistories.prev && item.riskHistories.prev[key]
                ? item.riskHistories.prev[key].medianRiskValue
                : undefined;
            const updatedShow = score && oldScore && score !== oldScore;

            newItem[key] = {
              ...item.riskHistories.current[key],
              score,
              oldScore,
              updatedShow,
            };
          });
        }
        return newItem;
      });

      const { shows, episodes } = await fetchEpisodes(newGarmShows, noEpisodes);
      return { shows, episodes };
    } catch (error) {
      return { shows: [], episodes: [] };
    }
  };

  const formatEpisodeData = (episodes: IGarmScoreDto[]) => {
    return episodes
      .map((each) =>
        [
          each['Episode Title'],
          each['Guid'],
          JSON.stringify(each['GARM 1']),
          each['GARM 1 Risk'],
          JSON.stringify(each['GARM 2']),
          each['GARM 2 Risk'],
          JSON.stringify(each['GARM 3']),
          each['GARM 3 Risk'],
          JSON.stringify(each['GARM 4']),
          each['GARM 4 Risk'],
          JSON.stringify(each['GARM 5 & 9']),
          each['GARM 5 & 9 Risk'],
          JSON.stringify(each['GARM 6']),
          each['GARM 6 Risk'],
          JSON.stringify(each['GARM 7']),
          each['GARM 7 Risk'],
          JSON.stringify(each['GARM 8']),
          each['GARM 8 Risk'],
          JSON.stringify(each['GARM 10']),
          each['GARM 10 Risk'],
          JSON.stringify(each['GARM 11 DSSI Topics']),
          JSON.stringify(each['GARM 11 DSSI Topics Utterances']),
          each['GARM 11 Risk'],
          JSON.stringify(each['GAMBLING']),
          each['GAMBLING Risk'],
          JSON.stringify(each['OCCULT']),
          each['OCCULT Risk'],
          JSON.stringify(each['NATURAL DISASTER']),
          each['NATURAL DISASTER Risk'],
          each['Audio File (AUTO)'],
          each.id,
          each['Podscribe Ep ID'],
          each['Episode Link'],
          each.createdAt,
          formatPodcastRating(each['Listeners'] || ''),
          each['Episode Date'],
          each.Show,
          each['Context Score'],
          each['Episode Number'],
          each['Show Code'],
          each['IAB Data'],
          each['Transcript Link (AUTO)'],
          each['Transcription'].slice(0, 24999),
          each['Transcription'].slice(25000, 49999),
          each['Transcription'].slice(50000, 74999),
          each['Transcription'].slice(75000, 99999),
        ]
          .map((value) => {
            if (!value) return value;
            let result = String(value).replace(/"/g, '""');
            if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
            return result;
          })
          .join(DELIMITER)
      )
      .join('\n');
  };

  const formatShowData = (shows: any[]) => {
    return shows
      .map((each: any) =>
        [
          each.id,
          each.Show,
          each.Host,
          each['RSS Feed'],
          each.ImageURL,
          each.collectionId,
          each.publisher,
          each.language,
          ...garmDashboardColumns.map(
            (item: string) => each[ShowHeaderMap[item]]
          ),
        ]
          .map((value) => {
            if (!value) return value;
            let result = String(value).replace(/"/g, '""');
            if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
            return result;
          })
          .join(DELIMITER)
      )
      .join('\n');
  };

  const handleDownloadAsJson = async () => {
    setJsonDownloading(true);
    setDownloadProgress(1);

    const maxPage = Math.ceil(totalShowsCount / 100);
    for (let page = 0; page < maxPage; page++) {
      if (cancelDownloading.current) {
        setJsonDownloading(false);
        break;
      }
      const { shows, episodes } = await fetchGarmShows(page);
      if (cancelDownloading.current) {
        setJsonDownloading(false);
        break;
      }
      setDownloadProgress(Math.ceil((100 * (page + 1)) / maxPage));
      await downloadAsJson({
        data: { shows, episodes },
        fileName: `${dashboardName}_${page + 1}_page Shows and Episodes`,
      });
    }

    setJsonDownloading(false);
    setCancelDownload(false);
    cancelDownloading.current = false;
  };

  const handleDownloadAsCsv = async () => {
    setCsvDownloading(true);
    setDownloadProgress(1);

    const maxPage = Math.ceil(totalShowsCount / 100);
    for (let page = 0; page < maxPage; page++) {
      if (cancelDownloading.current) {
        setCsvDownloading(false);
        break;
      }
      const { shows, episodes } = await fetchGarmShows(page);
      if (cancelDownloading.current) {
        setCsvDownloading(false);
        break;
      }
      setDownloadProgress(Math.ceil((100 * (page + 1)) / maxPage));
      const csvEpisodes = formatEpisodeData(episodes);
      await downloadAsCsv({
        data: csvEpisodes,
        headers: csvEpisodeHeaders,
        fileName: `${dashboardName}_${page + 1}_page Episodes`,
      });
      const csvShows = formatShowData(shows);
      await downloadAsCsv({
        data: csvShows,
        headers: [
          ...csvShowHeaders,
          ...garmDashboardColumns.map((item) =>
            item === 'Death, Injury or Military Conflict'
              ? 'Death Injury or Military Conflict'
              : item
          ),
        ],
        fileName: `${dashboardName}_${page + 1}_page Shows`,
      });
    }

    setCsvDownloading(false);
    setCancelDownload(false);
    cancelDownloading.current = false;
  };

  const handleDownloadAllShows = async () => {
    setShowsDownloading(true);

    const { shows } = await fetchGarmShows(0, true);

    const csvShows = formatShowData(shows);
    await downloadAsCsv({
      data: csvShows,
      headers: [
        ...csvShowHeaders,
        ...garmDashboardColumns.map((item) =>
          item === 'Death, Injury or Military Conflict'
            ? 'Death Injury or Military Conflict'
            : item
        ),
      ],
      fileName: `${dashboardName} Shows`,
    });

    setShowsDownloading(false);
  };

  const handleDownloadAllEpisodes = async () => {
    setShowsDownloading(true);

    const { shows } = await fetchGarmShows(0, true);
    let episodeIds: string[] = [];
    shows.forEach((show) => {
      if (show.filteredEpisodes) {
        episodeIds = [...episodeIds, ...show.filteredEpisodes];
      }
    });
    const { data } = await getGarmAllEpisodes({ episodeIds });

    await downloadAsCsv({
      data: data
        .map((each) =>
          [
            each['Episode Title'],
            each['Guid'],
            JSON.stringify(each['GARM 1']),
            each['GARM 1 Risk'],
            JSON.stringify(each['GARM 2']),
            each['GARM 2 Risk'],
            JSON.stringify(each['GARM 3']),
            each['GARM 3 Risk'],
            JSON.stringify(each['GARM 4']),
            each['GARM 4 Risk'],
            JSON.stringify(each['GARM 5 & 9']),
            each['GARM 5 & 9 Risk'],
            JSON.stringify(each['GARM 6']),
            each['GARM 6 Risk'],
            JSON.stringify(each['GARM 7']),
            each['GARM 7 Risk'],
            JSON.stringify(each['GARM 8']),
            each['GARM 8 Risk'],
            JSON.stringify(each['GARM 10']),
            each['GARM 10 Risk'],
            JSON.stringify(each['GARM 11 DSSI Topics']),
            JSON.stringify(each['GARM 11 DSSI Topics Utterances']),
            each['GARM 11 Risk'],
            JSON.stringify(each['GAMBLING']),
            each['GAMBLING Risk'],
            JSON.stringify(each['OCCULT']),
            each['OCCULT Risk'],
            JSON.stringify(each['NATURAL DISASTER']),
            each['NATURAL DISASTER Risk'],
            each.id,
            each['Episode Link'],
            each['Context Score'],
            each['Episode Date'],
            each.Show,
            JSON.stringify(each['IAB Categories']),
          ]
            .map((value) => {
              if (!value) return value;
              let result = String(value).replace(/"/g, '""');
              if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
              return result;
            })
            .join(DELIMITER)
        )
        .join('\n'),
      headers: csvAllEpisodeHeaders,
      fileName: `${dashboardName} Episodes`,
    });

    setShowsDownloading(false);
  };

  return {
    downloadProgress,
    jsonDownloading,
    csvDownloading,
    showsDownloading,
    onDownloadAsJson: handleDownloadAsJson,
    onDownloadAsCsv: handleDownloadAsCsv,
    onDownloadAllShows: handleDownloadAllShows,
    onDownloadAllEpisodes: handleDownloadAllEpisodes,
    cancelDownloading,
  };
};

export default useDownload;
